import React from "react"
import styled from "styled-components"
import BottomDividerImage from "../images/infos/bottom-divider.svg"
import EmailImage from "../images/infos/email.svg"
import FacbookImage from "../images/infos/facebook.svg"
import InstagramImage from "../images/infos/instagram.svg"
import LinkedinImage from "../images/infos/linkedin.svg"
import LocationImage from "../images/infos/location.svg"
import PhoneImage from "../images/infos/phone.svg"
import AthmaneImage from "../images/infos/profile.png"
import TikTokImage from "../images/infos/tiktok.svg"
import TopDividerImage from "../images/infos/top-divider.svg"
import TwitterImage from "../images/infos/twitter.svg"
import VideoImage from "../images/infos/video.png"
import WebsiteImage from "../images/infos/website.svg"
import "../info.css"
// @media (max-width: 768px) {
// }
const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  background: #222530;
  border-radius: 15px;
  width: 400px;
  position: relative;

  box-shadow: 7px 9px 5px 7px rgba(23, 9, 9, 0.36);
  -webkit-box-shadow: 7px 9px 5px 7px rgba(23, 9, 9, 0.36);
  -moz-box-shadow: 7px 9px 5px 7px rgba(23, 9, 9, 0.36);

  @media (max-width: 420px) {
    flex-direction: column;
    width: 90%;
  }
`

const LinkStyle = styled.a`
  color: white;
`

const Item = ({ img, link, other, id = 1 }) => (
  <div {...other} style={{ marginRight: 15 }} className={`slide-top-${id}`}>
    <LinkStyle href={link} target="_blank">
      <img src={img} height={30} style={{ marginBottom: 0 }} />
    </LinkStyle>
  </div>
)

const SocialMedia = () => {
  const [state, setState] = React.useState()

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        padding: 15,
        marginBottom: 0,
        zIndex: 20,
      }}
    >
      <Item link={"https://othmane.pro/"} img={WebsiteImage} id="1" />
      <Item
        link={"https://www.facebook.com/outman.djeff"}
        img={FacbookImage}
        id="2"
      />
      <Item
        link={"https://www.instagram.com/othmane_djafri/?hl=fr"}
        img={InstagramImage}
        id="3"
      />
      <Item
        id="4"
        link={
          "https://twitter.com/OthmaneDjafri?fbclid=IwAR1tBw0NTkNf-8Lb9fGG6jbErAateV_AaO9LnDcDYfDFTYItEFfFAH_T57I"
        }
        img={TwitterImage}
      />
      <Item
        link={"https://www.tiktok.com/@othmane_djafri"}
        img={TikTokImage}
        id="5"
      />
      <Item
        link={"https://www.linkedin.com/in/othmane-djafri-8bab50193/"}
        img={LinkedinImage}
        id="6"
      />
    </div>
  )
}

const ContactSection = () => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      flex: 1,
      height: "100%",
      justifyContent: "center",
      padding: 15,
    }}
  >
    <div style={{ display: "flex" }}>
      <img
        src={VideoImage}
        width={20}
        height={20}
        style={{ marginBottom: 0 }}
      />
      <p
        style={{
          fontSize: 17,
          fontWeight: 700,
          marginLeft: 10,
          marginBottom: 15,
        }}
      >
        Filmmaker and Vlogger (Algerian tourist)
      </p>
    </div>
    <div
      style={{ display: "flex", marginBottom: 10, alignItems: "center" }}
      data-sal="slide-right"
      data-sal-duration="1000"
      data-sal-delay="500"
    >
      <img
        src={EmailImage}
        width={20}
        height={20}
        style={{ marginBottom: 0, marginRight: 10 }}
      />
      <LinkStyle
        style={{ fontSize: 15, fontWeight: 700, marginBottom: 0 }}
        href="mailto:othmane.djafri94@gmail.com"
      >
        othmane.djafri94@gmail.com
      </LinkStyle>
    </div>

    <div
      style={{ display: "flex", alignItems: "center" }}
      data-sal="slide-right"
      data-sal-duration="1000"
      data-sal-delay="800"
    >
      <img
        src={PhoneImage}
        width={20}
        height={20}
        style={{ marginBottom: 0, marginRight: 10 }}
      />
      <LinkStyle
        href="tel:213 540 17 62 06"
        style={{ fontSize: 15, fontWeight: 700, marginBottom: 0 }}
      >
        +213 540 17 62 06
      </LinkStyle>
    </div>
    <div
      style={{ display: "flex", alignItems: "center", marginTop: 10 }}
      data-sal="slide-right"
      data-sal-duration="1000"
      data-sal-delay="1000"
    >
      <img
        src={LocationImage}
        width={20}
        height={20}
        style={{ marginBottom: 0, marginRight: 10 }}
      />
      <p style={{ fontSize: 15, fontWeight: 700, marginBottom: 0 }}>
        Bejaia, Algerie.
      </p>
    </div>
  </div>
)
const Infos = () => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
      id="wrapper"
    >
      <ItemContent data-sal="flip-up" data-sal-duration="1000">
        <img
          src={TopDividerImage}
          style={{
            position: "absolute",
            width: "400px",
            borderTopLeftRadius: "14px",
            borderTopRightRadius: "14px",
          }}
        />
        <img
          src={BottomDividerImage}
          style={{
            position: "absolute",
            bottom: 0,
            marginBottom: 0,
            width: "400px",
            borderBottomLeftRadius: "14px",
            borderBottomRightRadius: "14px",
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <div
            style={{
              zIndex: 10,
              background: "white",
              borderRadius: "50%",
              height: "110px",
              width: "110px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={AthmaneImage}
              width={100}
              height={100}
              style={{
                borderRadius: "50%",
                marginBottom: 0,
              }}
            />
          </div>
        </div>
        <ContactSection />
        <SocialMedia />
      </ItemContent>
    </div>
  )
}
export default Infos
